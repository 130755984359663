import samples, { requestType } from '..'

export default (host: string) => ({
    '/v1/public/auth/auth_info': {
        get get () {
            const url = `https://${host}/v1/public/auth/auth_info`
            return samples({ url })
        },
    },
    '/v1/public/auth/login': {
        get post () {
            const url = `https://${host}/v1/public/auth/login`
            const useToken = false
            const method = requestType.POST
            const data = '{"username":"test","password":"test"}'
            return samples({
                url, method, data, useToken,
            })
        },
    },
    '/v1/public/auth/refresh': {
        get post () {
            const url = `https://${host}/v1/public/auth/refresh`
            const method = requestType.POST
            const data = '{"refresh_token":"test"}'
            return samples({
                url, method, data,
            })
        },
    },
    '/v1/public/auth/revoke': {
        get post () {
            const url = `https://${host}/v1/public/auth/revoke`
            const method = requestType.POST
            const data = '{"token":"test"}'
            return samples({
                url, method, data,
            })
        },
    },
    '/v1/public/auth/revoke_all': {
        get post () {
            const url = `https://${host}/v1/public/auth/revoke_all`
            const method = requestType.POST
            return samples({
                url, method,
            })
        },
    },
    '/v1/public/auth/sessions': {
        get get () {
            const url = `https://${host}/v1/public/auth/sessions`
            return samples({ url })
        },
    },
    '/v1/public/auth/sessions/{session_id}': {
        get get () {
            const url = `https://${host}/v1/public/auth/sessions/1`
            return samples({ url, method: requestType.GET })
        },
        get delete () {
            const url = `https://${host}/v1/public/auth/sessions/1`
            return samples({ url, method: requestType.DELETE })
        },
    },
    '/v1/public/auth/short_refresh_token': {
        get get () {
            const url = `https://${host}/v1/public/auth/short_refresh_token`
            return samples({ url })
        },
    },
    '/v1/public/auth/signup': {
        get post () {
            const url = `https://${host}/v1/public/auth/signup`
            const method = requestType.POST
            const data = '{"email":"test@test.test","username":"test","first_name":"test","last_name":"test","password":"test","password2":"test"}'
            return samples({
                url, method, data, useToken: false,
            })
        },
    },
    '/v1/public/auth/reset_password': {
        get post () {
            const url = `https://${host}/v1/public/auth/reset_password`
            const method = requestType.POST
            const data = '{"email":"test@test.test","mobile":"false"}'
            return samples({
                url, method, data, useToken: false,
            })
        },
    },
    '/v1/public/auth/change_password': {
        get post () {
            const url = `https://${host}/v1/public/auth/change_password`
            const method = requestType.POST
            const data = '{"new_password":"test"}'
            return samples({
                url, method, data,
            })
        },
    },
    get '/v1/public/auth/users/{user_id}' () {
        const url = `https://${host}/v1/public/auth/users/1`
        return {
            get: samples({ url }),
        }
    },
    '/v1/public/auth/user/{user_id}/sessions': {
        get get () {
            const url = `https://${host}/v1/public/auth/user/1/sessions`
            return samples({ url })
        },
    },
    '/v1/public/auth/user/{user_id}/login': {
        get post () {
            const url = `https://${host}/v1/public/auth/user/1/login`
            const method = requestType.POST
            return samples({
                url, method,
            })
        },
    },
})
