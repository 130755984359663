import samples, { requestType } from '..'

export default (host: string) => ({
    '/v1/public/dashboard/exclusions': {
        get get () {
            const url = `https://${host}/v1/public/dashboard/exclusions`
            return samples({ url })
        },
        get post () {
            const url = `https://${host}/v1/public/dashboard/exclusions`
            const method = requestType.POST
            const data = '{"domain":"test.com","type":"ALLOWED"}'
            return samples({
                url, method, data,
            })
        },
    },
    '/v1/public/dashboard/exclusions/{exclusion_id}': {
        get get () {
            const url = `https://${host}/v1/public/dashboard/exclusions/1`
            return samples({ url })
        },
        get delete () {
            const url = `https://${host}/v1/public/dashboard/exclusions/1`
            const method = requestType.DELETE
            return samples({
                url, method,
            })
        },
    },
    '/v1/public/dashboard/dns_log/records': {
        get get () {
            const url = `https://${host}/v1/public/dashboard/dns_log/records`
            return samples({ url })
        },
    },
    '/v1/public/dashboard/categories': {
        get get () {
            const url = `https://${host}/v1/public/dashboard/categories`
            return samples({ url })
        },
    },
    '/v1/public/dashboard/policies': {
        get get () {
            const url = `https://${host}/v1/public/dashboard/policies`
            return samples({ url })
        },
    },
    '/v1/public/dashboard/policies/activate': {
        get post () {
            const url = `https://${host}/v1/public/dashboard/policies/activate`
            const method = requestType.POST
            const data = '{"policies":[9,7]}'
            return samples({
                url, method, data,
            })
        },
    },
    '/v1/public/dashboard/dns_log/activity': {
        get get () {
            const url = `https://${host}/v1/public/dashboard/dns_log/activity`
            return samples({ url })
        },
    },
    '/v1/public/dashboard/interfaces': {
        get get () {
            const url = `https://${host}/v1/public/dashboard/interfaces`
            return samples({ url })
        },

        get post () {
            const url = `https://${host}/v1/public/dashboard/interfaces`
            const method = requestType.POST
            const data = '{"interface":"127.0.0.1"}'
            return samples({
                url, method, data,
            })
        },
    },
    '/v1/public/dashboard/interfaces/{interface_id}': {
        get get () {
            const url = `https://${host}/v1/public/dashboard/interfaces/3`
            return samples({ url })
        },
        get delete () {
            const url = `https://${host}/v1/public/dashboard/interfaces/3`
            const method = requestType.DELETE
            return samples({
                url, method,
            })
        },
    },
    '/v1/public/dashboard/helpers/client_ip': {
        get get () {
            const url = `https://${host}/v1/public/dashboard/helpers/client_ip`
            return samples({ url })
        },
    },
    '/v1/public/dashboard/helpers/doh_settings': {
        get get () {
            const url = `https://${host}/v1/public/dashboard/helpers/doh_settings`
            return samples({ url })
        },
    },
    '/v1/public/dashboard/helpers/provider_config': {
        get get () {
            const url = `https://${host}/v1/public/dashboard/helpers/provider_config`
            const useToken = false
            return samples({ url, useToken })
        },
    },
    '/v1/public/dashboard/settings': {
        get get () {
            const url = `https://${host}/v1/public/dashboard/settings`
            return samples({ url })
        },

        get put () {
            const url = `https://${host}/v1/public/dashboard/settings`
            const method = requestType.PUT
            const data = '{"safesearch":"true","safeyoutube":"false"}'
            return samples({
                url, method, data,
            })
        },

        get patch () {
            const url = `https://${host}/v1/public/dashboard/settings`
            const method = requestType.PATCH
            const data = '{"safesearch":"true","safeyoutube":"false"}'
            return samples({
                url, method, data,
            })
        },
    },
    '/v1/public/dashboard/schedules': {
        get get () {
            const url = `https://${host}/v1/public/dashboard/schedules`
            return samples({ url })
        },

        get post () {
            const url = `https://${host}/v1/public/dashboard/schedules`
            const method = requestType.POST
            const data = '{"policies":[9],"weekdays":["tue","wed"],"start_time":"00:00","stop_time":"01:00"}'
            return samples({
                url, method, data,
            })
        },
    },
    '/v1/public/dashboard/schedules/{schedule_id}': {
        get get () {
            const url = `https://${host}/v1/public/dashboard/schedules/3`
            return samples({ url })
        },
        get delete () {
            const url = `https://${host}/v1/public/dashboard/schedules/3`
            const method = requestType.DELETE
            return samples({
                url, method,
            })
        },
    },
})
