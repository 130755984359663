import type { Middleware } from 'openapi-fetch'

declare global {
    // eslint-disable-next-line vars-on-top, no-var
    var NEW_AUTH_API: any
}

export interface TokenDetailsInterface {
    access_token: string
    refresh_token: string
    expires_in: string
}

interface AuthMiddlewareFactoryInterface {
    isTokenExpired: () => boolean
    getRefreshTokenPendingStatus: () => boolean
    startRefreshTokenUpdate: () => void
    waitForRefreshTokenWillBeUpdated: () => void
    getAccessToken: () => void
    getRefreshToken: () => void
    saveRefreshedToken: (params: TokenDetailsInterface) => void
    clearPending: () => void
}

export const noNeedAuthTokenForEndpoint = (endpoint: string) => {
    return [
        '/v1/public/auth/refresh',
        '/v1/public/auth/login',
        '/v1/public/auth/signup',
        '/v1/public/auth/reset_password',
        '/v1/public/dashboard/helpers/provider_config',
    ].includes(endpoint) || endpoint.startsWith('/v1/public/auth/change_password')
}

export const skipErrorHandling = (endpoint: string, status: number) =>
    (endpoint === '/v1/public/dashboard/interfaces' && [409, 422].includes(status))
    || (endpoint.startsWith('/v1/public/dashboard/interfaces/') && status === 404)
    || (endpoint === '/v1/public/dashboard/exclusions' && status === 409)
    || (endpoint === '/v1/public/dashboard/schedules' && [409, 422].includes(status))
    || (endpoint === '/v1/public/dashboard/interfaces' && [409, 422].includes(status))
    || (endpoint.startsWith('/v1/public/dashboard/schedules/') && status === 404)
    || (endpoint === '/v1/public/auth/login' && [401].includes(status))
    || (endpoint.startsWith('/v1/public/db_explorer/search_domain') && status === 400)
    || (endpoint === '/v1/public/invoicing/isp' && [400, 409].includes(status))
    || (endpoint === '/v1/public/invoicing/zoho/auth_info' && status === 400)
    || (endpoint === '/v1/public/isp/subscriptions' && status === 409)
    || (endpoint.startsWith('/v1/public/isp/subscriptions/') && status === 422)
    || (endpoint.endsWith('/dynamic_dns/domains') && status === 409)

export const skipRequestsParamsForBreadCrumbs = (schemaPath: string) =>
    schemaPath.startsWith('/v1/public/auth')

export default ({
    isTokenExpired,
    getRefreshTokenPendingStatus,
    startRefreshTokenUpdate,
    waitForRefreshTokenWillBeUpdated,
    getAccessToken,
    getRefreshToken,
    saveRefreshedToken,
    clearPending,
}: AuthMiddlewareFactoryInterface): Middleware => ({
    async onRequest ({ request, schemaPath }) {
        // uncomment nextline to test with ngrok
        // request.headers.set('ngrok-skip-browser-warning', 'true')
        if (noNeedAuthTokenForEndpoint(schemaPath)) {
            return
        }
        if (isTokenExpired() && !getRefreshTokenPendingStatus()) {
            await startRefreshTokenUpdate()
            const refreshData = await globalThis.NEW_AUTH_API.refresh(getRefreshToken())
            if (!refreshData) {
                return undefined
            }
        }
        if (getRefreshTokenPendingStatus()) {
            await waitForRefreshTokenWillBeUpdated()
            await clearPending()
        }
        const accessToken = getAccessToken()
        request.headers.set('Authorization', `Bearer ${accessToken}`)
        return request
    },
    async onResponse ({ response, options }) {
        if (response.status === 500) {
            return
        }
        const endpoint = response.url.substr(options.baseUrl.length)
        const data = await response.clone().json()
        if (endpoint === '/v1/public/auth/refresh' || endpoint === '/v1/public/auth/login') {
            if (response.status > 200) {
                await clearPending()
                return
            }
            await saveRefreshedToken({
                ...data,
                expires_in: new Date().getTime() / 1000 + data.expires_in,
            })
        }
    },
})
