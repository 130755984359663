export const CHANNEL_NAME = 'KIDZONET'
export const LOGIN_MESSAGE = 'LOGIN'
export const LOGOUT_MESSAGE = 'LOGOUT'
export const MINDCONTROL_MESSAGE = 'MINDCONTROL'
export const RELOAD_PAGE = 'RELOAD_PAGE'
export const ISP_ADMIN_SUBSCRIBER_NETWORK_CHANGED = 'ISP_ADMIN_SUBSCRIBER_NETWORK_CHANGED'

export const SENDER_ID_KEY = 'BROADCAST_CHANNEL_SENDER_ID'

export const getUniqueId = () => `_${Math.random().toString(36).substr(2, 9)}`

export const getSenderId = () => {
    let senderId = sessionStorage.getItem(SENDER_ID_KEY)
    if (!senderId) {
        senderId = getUniqueId()
        sessionStorage.setItem(SENDER_ID_KEY, senderId)
    }
    return senderId
}

export const tabSenderId = getSenderId()

export const getBroadcastChannel = () => {
    if (window.BroadcastChannel) {
        return new BroadcastChannel(CHANNEL_NAME)
    }
    return null
}

export const makeMessage = (message: string) => ({
    id: getUniqueId(),
    senderId: tabSenderId,
    message,
})

export const sendMessage = (message: string) => {
    const broadcastChannel = getBroadcastChannel()
    if (broadcastChannel === null) {
        return
    }
    broadcastChannel.postMessage(makeMessage(message))
}

export const sendBCLoginMessage = () => {
    sendMessage(LOGIN_MESSAGE)
}

export const sendBCLogoutMessage = () => {
    sendMessage(LOGOUT_MESSAGE)
}

export const sendBCMindControlMessage = () => {
    sendMessage(MINDCONTROL_MESSAGE)
}

export const sendBCReloadPageMessage = () => {
    sendMessage(RELOAD_PAGE)
}

export const sendBCISPAdminNetworkChangedMessage = () => {
    sendMessage(ISP_ADMIN_SUBSCRIBER_NETWORK_CHANGED)
}

export const useBroadcastOnMessage = (messageName, callback) => {
    const broadCastChannel = getBroadcastChannel()
    if (broadCastChannel === null) {
        return
    }
    broadCastChannel.onmessage = (e) => {
        const { message } = e.data
        if (message === messageName) {
            callback()
        }
    }
}

export const onReceiveBCISPAdminNetworkAddedMessage = (callback) => {
    useBroadcastOnMessage(ISP_ADMIN_SUBSCRIBER_NETWORK_CHANGED, callback)
}

export const useReloadPageOnBroadcastMessage = () => {
    const broadCastChannel = getBroadcastChannel()
    if (broadCastChannel === null) {
        return
    }
    broadCastChannel.onmessage = (e) => {
        const { senderId, message } = e.data
        if (senderId === tabSenderId) {
            return
        }
        if ([RELOAD_PAGE, LOGIN_MESSAGE, LOGOUT_MESSAGE, MINDCONTROL_MESSAGE].includes(message)) {
            window.location.reload()
        }
    }
}
