<script lang="ts" setup>
import {
    computed, nextTick, onMounted, ref, watch,
} from 'vue'

// @ts-expect-error code samples
import * as redoc from 'redoc/bundles/redoc.standalone'

// TODO: export types doesnt work now https://github.com/vuejs/core/issues/4294
interface ApiSelectorProps {
    scheme: string
    host: string
}

const props = withDefaults(defineProps<ApiSelectorProps>(), {
    scheme: 'auth_v1',
    host: '',
})

const initialized = ref(false)
const redocContainer = ref(null)

const isAuthV1Scheme = computed(() => props.scheme === 'auth_v1')
const isDashboardV1Scheme = computed(() => props.scheme === 'dashboard_v1')
const isIspV1Scheme = computed(() => props.scheme === 'isp_v1')
const isKznctlAuthScheme = computed(() => props.scheme === 'kznctlAuth')
const isBillingScheme = computed(() => props.scheme === 'billing')
const isDbExplorerScheme = computed(() => props.scheme === 'dbExplorer')

const samples = computed(() => {
    if (isAuthV1Scheme.value) {
        return () => import('./code-samples/kidzonet/auth')
    }
    if (isDashboardV1Scheme.value) {
        return () => import('./code-samples/kidzonet/dashboard')
    }
    if (isIspV1Scheme.value) {
        return () => import('./code-samples/kidzonet/isp')
    }
    return null
})

const isKidzonetScheme = computed(
    () => isDashboardV1Scheme.value || isIspV1Scheme.value || isAuthV1Scheme.value,
)
const hostname = computed(() => {
    if (isKidzonetScheme.value) {
        if (props.host === 'rest.control.kidzonet.io') {
            return 'rest.kidzonet.io'
        }
        if (props.host === 'rest.control.kidzonet.cloud') {
            return 'rest.kidzonet.cloud'
        }
    }
    return props.host
})

interface OpenapiScheme {
    host?: string
    schemes?: string[]
    paths?: any
}

const getOpenapiScheme = async (): Promise<OpenapiScheme> => {
    if (isAuthV1Scheme.value) {
        const scheme = await import('./swagger/kidzonet/auth_api.openapi.json')
        return scheme.default
    }
    if (isDashboardV1Scheme.value) {
        const scheme = await import('./swagger/kidzonet/dashboard_api.openapi.json')
        return scheme.default
    }
    if (isIspV1Scheme.value) {
        const scheme = await import('./swagger/kidzonet/isp_api.openapi.json')
        return scheme.default
    }
    if (isKznctlAuthScheme.value) {
        const scheme = await import('./swagger/kznctl_pb/auth/public.swagger.json')
        return scheme.default
    }
    if (isBillingScheme.value) {
        const scheme = await import('./swagger/kznctl_pb/billing/public.swagger.json')
        return scheme.default
    }
    if (isDbExplorerScheme.value) {
        const scheme = await import('./swagger/kznctl_pb/db_explorer/public.swagger.json')
        return scheme.default
    }
    return {}
}

const getSanitizedScheme = async () => {
    const scheme = await getOpenapiScheme()
    scheme.host = hostname.value
    scheme.schemes = ['https']
    if (samples.value) {
        const samplesPromise: any = await samples.value()
        const codeSamples = samplesPromise.default(hostname.value)
        Object.keys(codeSamples).forEach((sample) => {
            Object.keys(codeSamples[sample]).forEach((request) => {
                const result = codeSamples[sample][request]
                if (result) {
                    if (scheme.paths?.[sample] && scheme.paths[sample][request]) {
                        scheme.paths[sample][request]['x-codeSamples'] = result
                    } else {
                        console.warn('code sample doesnt exists', sample, request)
                    }
                } else {
                    console.warn('code sample doesnt exists', sample, request)
                }
            })
        })
    }
    return scheme
}

const initilizeRedoc = async () => {
    window.scrollTo(0, 0)
    if (initialized.value) {
        window.location.hash = ''
    }
    const scheme = await getSanitizedScheme()
    await nextTick()
    redoc.init(
        scheme,
        {
            scrollYOffset: 64,
            hideDownloadButton: true,
        },
        redocContainer.value,
    )
}

onMounted(async () => {
    await initilizeRedoc()
    initialized.value = true
})

watch(() => props.scheme, () => {
    initilizeRedoc()
})
</script>

<template>
    <div ref="redocContainer" />
</template>
