import { aliases, md } from 'vuetify/iconsets/md'
import { fa } from 'vuetify/iconsets/fa'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { createVuetify } from 'vuetify'

import theme from '@kidzonet/vuetify-config/isp-admin-theme'
import { defaultVuetifyComponentsStyle } from '@kidzonet/vuetify-config/vuetify'
import { kidzonetDashboardIconSet } from '@kidzonet/dashboard-icons'

const kidzonetDashboardAliases = {
    Trash: 'delete',
}

export default createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'kidzonetDashboardIconSet',
        aliases: {
            ...aliases,
            ...kidzonetDashboardAliases,
        },
        sets: {
            kidzonetDashboardIconSet,
            fa,
            md,
        },
    },
    theme,
    defaults: defaultVuetifyComponentsStyle,
})
