import samples, { requestType } from '..'

export default (host: string) => ({
    get '/v1/public/isp/dns_log/records' () {
        const url = `https://${host}/v1/public/isp/dns_log/records`
        return {
            get: samples({ url }),
        }
    },
    get '/v1/public/isp/dns_log/records/amount' () {
        const url = `https://${host}/v1/public/isp/dns_log/records/amount`
        return {
            get: samples({ url }),
        }
    },
    get '/v1/public/isp/exclusions' () {
        const url = `https://${host}/v1/public/isp/exclusions`
        const data = '{"domain":"example.com","type":"WHITE"}'
        return {
            get: samples({ url }),
            post: samples({ url, method: requestType.POST, data }),
        }
    },
    get '/v1/public/isp/exclusions/{exclusion_id}' () {
        const url = `https://${host}/v1/public/isp/exclusions/1`
        const data = '{"domain":"example.com","type":"WHITE"}'
        return {
            get: samples({ url }),
            delete: samples({ url, method: requestType.DELETE }),
            put: samples({ url, method: requestType.PUT, data }),
            patch: samples({ url, method: requestType.PATCH, data }),
        }
    },
    get '/v1/public/isp/global_settings/{name}' () {
        const url = `https://${host}/v1/public/isp/global_settings/LABEL`
        const data = '{"label":"test"}'
        return {
            get: samples({ url }),
            put: samples({ url, method: requestType.PUT, data }),
            patch: samples({ url, method: requestType.PATCH, data }),
        }
    },
    get '/v1/public/isp/subscriptions' () {
        const url = `https://${host}/v1/public/isp/subscriptions`
        const data = '{"email":"test", "username":"test"}'
        return {
            get: samples({ url }),
            post: samples({ url, method: requestType.POST, data }),
        }
    },
    get '/v1/public/isp/subscriptions/{external_id}' () {
        const url = `https://${host}/v1/public/isp/subscriptions/1`
        const data = '{"email":"test", "username":"test"}'
        return {
            get: samples({ url }),
            delete: samples({ url, method: requestType.DELETE }),
            put: samples({ url, method: requestType.PUT, data }),
            patch: samples({ url, method: requestType.PATCH, data }),
        }
    },
    get '/v1/public/isp/subscriptions_by_id' () {
        const url = `https://${host}/v1/public/isp/subscriptions_by_id?ids=3`
        return {
            get: samples({ url }),
        }
    },
    get '/v1/public/isp/domain_categories/{domain}' () {
        const url = `https://${host}/v1/public/isp/domain_categories/1?recursion=true`
        return {
            get: samples({ url }),
        }
    },
    get '/v1/public/isp/eventlog' () {
        const url = `https://${host}/v1/public/isp/eventlog`
        return {
            get: samples({ url }),
        }
    },
})
