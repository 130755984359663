import type { Middleware } from 'openapi-fetch'
import * as Sentry from '@sentry/vue'
import { clearBrowser } from '@kidzonet/vue3-logout'
import { notify } from '@kyvg/vue3-notification'
// @ts-expect-error strange import problems
import i18n from '@kidzonet/vue-i18n-package/obj.d.ts'

import AuthMiddlewareFactory, {
    TokenDetailsInterface,
    skipErrorHandling,
    skipRequestsParamsForBreadCrumbs,
} from '@kidzonet/ts-api-refresh-token-middleware'

const MAX_TRIES = 20
const TRY_TIMEOUT = 1000

export const clearPending = () => {
    localStorage.setItem('kidzonet-refresh-token-pending', 'false')
    localStorage.setItem('kidzonet-child-refresh-token-pending', 'false')
}

export const getAccessToken = () => {
    return localStorage.getItem('kidzonet-child-token')
      || localStorage.getItem('kidzonet-token')
}

export const getExpiresIn = () => {
    const result = localStorage.getItem('kidzonet-child-token-expires-in')
      || localStorage.getItem('kidzonet-token-expires-in') || 0
    return Number(result)
}

export const getRefreshTokenPendingStatus = () => {
    return localStorage.getItem('kidzonet-child-refresh-token-pending') === 'true'
      || localStorage.getItem('kidzonet-refresh-token-pending') === 'true'
}

export const startRefreshTokenUpdate = () => {
    if (localStorage.getItem('kidzonet-child-refresh-token')) {
        localStorage.setItem('kidzonet-child-refresh-token-pending', 'true')
    } else {
        localStorage.setItem('kidzonet-refresh-token-pending', 'true')
    }
}

export const checkRefreshTokenPending = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(getRefreshTokenPendingStatus())
        }, TRY_TIMEOUT)
    })
}

export const isTokenExpired = () => {
    return (new Date().getTime() / 1000) - 60 >= getExpiresIn()
}

export const waitForRefreshTokenWillBeUpdated = async (count = MAX_TRIES): Promise<any> => {
    if (count > 0) {
        const result = await checkRefreshTokenPending()
        if (result) {
            return waitForRefreshTokenWillBeUpdated(count - 1)
        }
    }
}

export const getRefreshToken = () => {
    return localStorage.getItem('kidzonet-child-refresh-token')
      || localStorage.getItem('kidzonet-refresh-token')
}

export const mindcontrol = ({ access_token, refresh_token, expires_in }: TokenDetailsInterface) => {
    localStorage.setItem('kidzonet-child-token', access_token)
    localStorage.setItem('kidzonet-child-refresh-token', refresh_token)
    localStorage.setItem('kidzonet-child-token-expires-in', expires_in)
    localStorage.setItem('kidzonet-child-refresh-token-pending', 'false')
}

export const saveRefreshedToken = ({ access_token, refresh_token, expires_in }: TokenDetailsInterface) => {
    if (localStorage.getItem('kidzonet-child-refresh-token')) {
        mindcontrol({ access_token, refresh_token, expires_in })
    } else {
        localStorage.setItem('kidzonet-token', access_token)
        localStorage.setItem('kidzonet-refresh-token', refresh_token)
        localStorage.setItem('kidzonet-token-expires-in', expires_in)
        localStorage.setItem('kidzonet-refresh-token-pending', 'false')
    }
}

export const AuthMiddleware = AuthMiddlewareFactory({
    isTokenExpired,
    getRefreshTokenPendingStatus,
    startRefreshTokenUpdate,
    waitForRefreshTokenWillBeUpdated,
    getAccessToken,
    getRefreshToken,
    saveRefreshedToken,
    clearPending,
})

const showEDefaultrrorAlert = () => {
    const { t } = i18n.global
    notify({
        type: 'error',
        title: t('errors.error'),
        text: t('errors.server_error'),
    })
}

export const ErrorHandlingMiddleware: Middleware = {
    async onRequest ({ request, schemaPath }) {
        if (request.method === 'GET') {
            return
        }
        if (skipRequestsParamsForBreadCrumbs(schemaPath)) {
            return
        }
        try {
            const body = request.clone().body
            if (!body) {
                return request
            }
            const params = await new Response(body).json()
            Sentry.addBreadcrumb({
                category: 'request-params',
                message: `${request.method} ${schemaPath}`,
                data: params,
                level: 'info',
            })
        } catch (e) {
            console.error(e)
            Sentry.captureException(e)
        }
        return request
    },
    async onResponse ({ response, options }) {
        if (response.status < 400) {
            return response
        }

        const endpoint = response.url.substr(options.baseUrl.length)

        let data: any
        try {
            data = await response.clone().json()
        } catch (e) {
            if (response.status === 500) {
                const errorMessage = `500 error response code on ${endpoint}`
                Sentry.captureException(errorMessage)
                console.error(errorMessage)
            } else {
                Sentry.captureException(e)
                console.error(e)
            }
            showEDefaultrrorAlert()
            return
        }

        if (skipErrorHandling(endpoint, data.status || response.status)) {
            return
        }
        if (response.status === 401) {
            clearBrowser()
            return
        }

        const e = data?.detail?.[0] || {}
        showEDefaultrrorAlert()
        if (
            (e?.error?.message && e?.error?.message?.indexOf?.('network is offline') > -1)
            || (e?.message && e?.message?.indexOf?.('network is offline') > -1)
            || e?.name === 'ChunkLoadError'
            || e?.error?.name === 'ChunkLoadError'
            || e?.body?.detail === 'Token expired'
            || response.status === 500
        ) {
            return
        }
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(e?.body?.message || e.msg || String(e))
        }
    },
}
