const tokenStub = 'Bearer your-token'

const emptyStringForScroll = `

`

export enum requestType {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

interface requestParams {
    url: string
    method?: requestType
    data?: string
    useToken?: boolean
}

export const curlSampleObject = ({
    url,
    method = requestType.GET,
    data = '',
    useToken = true,
}: requestParams) => {
    let source = `curl -X ${method} '${url}'`
    if (method !== requestType.GET && data) {
        source = `${source} \\
--data-raw '${data}'`
    }
    if (useToken) {
        source = `${source} \\
-H 'authorization: ${tokenStub}'`
    }
    source = `${source} ${emptyStringForScroll}`
    return {
        lang: 'shell',
        label: 'curl',
        source,
    }
}

export const jsSampleObject = ({
    url,
    method = requestType.GET,
    data = '',
    useToken = true,
}: requestParams) => {
    let source = `fetch('${url}',`
    const params = {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
    }
    if (useToken) {
        // @ts-expect-error code samples
        params.headers.authorization = tokenStub
    }
    if (data) {
        // @ts-expect-error code samples
        params.body = data
    }
    source = `${source} ${JSON.stringify(params, null, 2)})`
    source = `${source} ${emptyStringForScroll}`
    return {
        lang: 'JavaScript',
        source,
    }
}

export const pythonSampleobject = ({
    url,
    method = requestType.GET,
    data = '',
    useToken = true,
}: requestParams) => {
    let source = `from urllib.request import urlopen, Request

httprequest = Request("${url}", method="${method}")
httprequest.add_header('Content-Type', 'application/json')`

    if (useToken) {
        source = `${source}
httprequest.add_header("authorization", "${tokenStub}")`
    }
    let openRequest = 'urlopen(httprequest)'
    if (data) {
        openRequest = `urlopen(httprequest, data=${JSON.stringify(data)}.encode())`
    }
    source = `${source}

with ${openRequest} as response:
    print(response.read().decode())
`
    source = `${source} ${emptyStringForScroll}`
    return {
        lang: 'Python',
        source,
    }
}
export default ({
    url,
    method = requestType.GET,
    data = '',
    useToken = true,
}: requestParams) => ([
    curlSampleObject({
        url, method, data, useToken,
    }),
    jsSampleObject({
        url, method, data, useToken,
    }),
    pythonSampleobject({
        url, method, data, useToken,
    }),
])
